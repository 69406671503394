<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="webpageWrap preview">
    <img :src="webpage" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      webpage: require("../assets/webpage.jpg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.webpageWrap {
  width: 100%;
  height: 100%;
  user-select: none;
  img {
    width: 100%;
    max-height: 100%;
  }
}
</style>
